import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/employeeCode/getPage',
   delete: '/event/enterpriseWechat/employeeCode/removeEmployeeCode',
   save: '/event/enterpriseWechat/employeeCode/saveEmployeeCode',
   ewUsers: '/event/enterpriseWechat/ewUser/select',
   tags: '/event/enterpriseWechat/tag/select',
   material: '/event/enterpriseWechat/material/uploadMaterial'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function removeEmployeeCode(params) {
   return request({
      url: api.delete,
      method: 'POST',
      data: params
   })
}

export function saveEmployeeCode(params) {
   return request({
      url: api.save,
      method: 'POST',
      data: params
   })
}

export function getTagList(params = {}) {
   return request({
      url: api.tags,
      method: 'POST',
      data: params
   })
}

export function getEwUsers(params = {}) {
   return request({
      url: api.ewUsers,
      method: 'POST',
      data: params
   })
}

export function uploadMaterial(params) {
   return request({
      url: api.material,
      method: 'POST',
      data: params
   })
}
