<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
      <div class="basic-info">
        <h6>基础信息</h6>
        <div class="p-left-30">
          <el-form-item :label="text.t1" prop="name" required>
            <el-input v-model="ruleForm.name" class="width-370" placeholder="活动名称"></el-input>
          </el-form-item>
          <el-form-item label="使用员工" :prop="users">
            <el-button @click="selectEmUsers">选择员工</el-button>
          </el-form-item>
          <slot name="appoint-crowd"></slot>
        </div>
      </div>
      <el-row class="set-message">
        <h6>{{ text.t2 }}</h6>
        <el-row class="p-left-30">
          <el-col :span="12" class="left-setting">
            <slot name="alert-text"></slot>
            <slot name="welcome-way"></slot>
            <el-form-item :label="text.t3" :prop="messageCont" required>
              <el-card shadow="never">
                <el-input id="singleText" v-model="ruleForm[messageCont][0].contentObject.content" type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" maxlength="100" show-word-limit placeholder="请输入欢迎语"></el-input>
                <div class="footer">
                  <span @click="insertText('【客户昵称】')">【客户昵称】</span>
                </div>
              </el-card>
            </el-form-item>
            <el-form-item label="更多">
              <!-- <el-radio-group v-model="ruleForm[messageCont][1].msgType">
                <el-radio label="IMAGE">图片</el-radio>
                 <el-radio label="LINK">链接</el-radio>
              </el-radio-group>-->
            </el-form-item>
            <!-- <div class="m-left-56" v-if="ruleForm[messageCont][1].msgType === 'LINK'">
              <el-form-item label="链接地址">
                <el-input v-model="ruleForm[messageCont][1].contentObject.url" class="width-280" placeholder="链接地址"></el-input>
              </el-form-item>
              <el-form-item label="链接标题">
                <el-input v-model="ruleForm[messageCont][1].contentObject.title" class="width-280" placeholder="链接标题"></el-input>
              </el-form-item>
              <el-form-item label="链接内容">
                <el-input v-model="ruleForm[messageCont][1].contentObject.desc" class="width-280" type="textarea" placeholder="链接内容" maxlength="40"></el-input>
              </el-form-item>
            </div>-->
            <el-form-item label="上传图片">
              <el-upload
                  class="avatar-uploader" ref="upload" :show-file-list="false"
                  action="#" :auto-upload="false" :on-change="handleUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-alert title="图片最大为10M，支持bmp/png/jpeg/jpg/gif格式" type="info" show-icon></el-alert>
          </el-col>
          <el-col :span="12" class="right-preview">
            <div class="preview">
              <el-row class="news-box" type="flex">
                <img src="@/assets/head-portrait.png" alt="头像">
                <p>{{ ruleForm[messageCont][0].contentObject.content }}</p>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-row>
      <slot name="other-set"></slot>
      <el-form-item>
        <el-button @click="submitPublish" type="primary">发布活动</el-button>
      </el-form-item>
    </el-form>
    <!-- 选择用户 -->
    <el-dialog title="选择用户" :visible.sync="userVisible" width="450px">
      <el-tree ref="userTree" :data="userTree" show-checkbox default-expand-all node-key="id" highlight-current
               :props="{ children: 'children', label: 'name' }"></el-tree>
      <span slot="footer">
         <el-button @click="userVisible = false">取消</el-button>
         <el-button @click="saveCheckUser">确定</el-button>
       </span>
    </el-dialog>
  </div>
</template>
<script>
import { getEwUsers, uploadMaterial } from '@/api/data/wechat/liveCode'
import { beforeUpload } from '@/common/js/common'
import { mapState } from 'vuex'
export default {
  props: ['messageCont', 'users', 'form', 'text'],
  data () {
    let validContent = (rule, value, callback) => {
      if (!value[0].contentObject.content) callback(new Error('消息内容不能为空！'))
      else callback()
    }
    return {
      ruleForm: {
        name: '',
        [this.users]: [],
        [this.messageCont]: [
          { contentObject: { content: '' }, msgType: 'TXT' },
          { contentObject: { id: '', /*url: '', title: '', desc: '',*/ },
            msgType: 'IMAGE'
          }
        ]
      },
      rules: {
        name: [{ required: true, message: '活动名称不能为空！' }],
        [this.users]: [{ required: true, message: '用户不能为空！' }],
        [this.messageCont]: [{ required: true, validator: validContent }]
      },
      userVisible: false,
      userTree: [],
      welcomeWay: 'NONE',
      welcomeType: '',
      imageUrl: '',
      action: ''
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.action = this.$route.query.action
    this.action === 'edit' && this.getActivityInfo()
  },
  methods: {
    // 获取编辑消息
    getActivityInfo() {
      const activityInfo = JSON.parse(sessionStorage.getItem('activityInfo'));
      this.ruleForm.name = activityInfo.name
      activityInfo[this.messageCont] && activityInfo[this.messageCont].forEach((item, index) => {
        this.ruleForm[this.messageCont][index].contentObject = item.contentObject
        this.ruleForm[this.messageCont][index].msgType = item.msgType
      })
      this.ruleForm[this.users] = activityInfo[this.users].map(i => ({ userId: i.userId, userName: i.userName }))
    },
    // 选择使用用户
    selectEmUsers() {
      this.userVisible = true
      if (this.userTree.length > 0) return
      getEwUsers({ companyId: this.hotelInfo.storeId }).then(({ success, records }) => {
        if (!success) return
        this.userTree = this.buildTreeData(records)
        if (this.action === 'edit') {
          this.$refs.userTree.setCheckedKeys([ ...this.ruleForm[this.users].map(i => i.userId) ])
        }
      })
    },
    buildTreeData(data) {
      const cloneData = JSON.parse(JSON.stringify(data))
      const newData = cloneData.filter(father => {
        const branchArr = cloneData.filter(child => father.id === child.parentId)
        if (branchArr.length > 0) {
          father.children = branchArr
        } else {
          father.children = []
          father.isLeaf = true // 设置为叶子节点
        }
        return father.parentId === '0'
      })
      if (newData.length > 0) {
        return newData
      } else {
        return cloneData
      }
    },
    // 确定选中用户
    saveCheckUser() {
      this.userVisible = false
      const checkUser = this.$refs.userTree.getCheckedNodes()
      this.ruleForm[this.users] = checkUser.filter(i => {
        return i.isUser === 'true'
      }).map(i => {
        return { userId: i.id, userName: i.name }
      })
    },
    // 插入文本
    async insertText(value) {
      const textNode = document.getElementById('singleText');
      if (textNode.selectionStart || textNode.selectionStart === 0) {
        const startPos = textNode.selectionStart
        const endPos = textNode.selectionEnd
        this.ruleForm[this.messageCont][0].contentObject.content = textNode.value.substring(0, startPos) + value + textNode.value.substring(endPos, textNode.value.length)
        await this.$nextTick()
        textNode.focus()
        textNode.setSelectionRange(endPos + value.length, endPos + value.length )
      } else this.ruleForm[this.messageCont][0].contentObject.content += value
    },
    // 处理上传
    handleUpload(file) {
      const error = beforeUpload(file)
      if (error) return this.$message.error(error)
      this.imageUrl = URL.createObjectURL(file.raw);
      const params = new FormData()
      params.set('file', file.raw)
      params.set('hotelId', this.hotelInfo.id)
      params.set('companyId', this.hotelInfo.storeId)
      uploadMaterial(params).then(({ success, records }) => {
        if (!success) return
        this.ruleForm[this.messageCont][1].contentObject.id = records.id
        this.$message({ message: '上传成功', type: 'success' })
      })
    },
    // 提交发布
    submitPublish() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        const ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        if (!this.ruleForm[this.messageCont][1].contentObject.id) ruleForm[this.messageCont].pop()
        this.$emit('publish', ruleForm)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form{
  background: #FFFFFF; padding: 30px;
  .m-left-56{ margin-left: 56px }
  h6 { font-size: 16px; margin-bottom: 20px; }
  .basic-info{ margin-bottom: 40px }
  .set-message{
    margin-bottom: 40px;
    .left-setting{
      padding: 0 20px 0 0;
      .el-card{
        width: 450px!important; height: 200px; position: relative;
        .footer{
          position: absolute; bottom: 0; left: 0; border-top: #EBEEF5 solid 1px;
          width: 100%; text-indent: 10px; cursor: pointer;
        }
      }
    }
    .right-preview{
      background-color: #e5e5e5; padding: 20px 0; text-align: center;
      .preview{
        width: 333px; height: 615px; margin: 0 auto; overflow: hidden;
        background: url('../../assets/preview-img-bg.png') no-repeat left top / 100% 100%;
        .news-box {
          margin: 110px 0 0 0; padding: 0 10px;
          img{ width: 50px; height: 50px }
          p{
            padding: 10px; font-size: 12px; color: #999999; background: #FFFFFF;
            border-radius: 10px; border: #e5e5e5 1px solid; margin-left: 5px;
            line-height: 21px; text-align: left;
          }
        }
      }
    }
  }
  ::v-deep .el-form-item__error{ margin-left: 80px }
}
</style>
